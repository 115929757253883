/* index.css index css */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css");

@layer components {
  .bg {
    @apply bg-gray-50 bg-opacity-40 dark:bg-gray-800;
  }

  .text-td {
    @apply whitespace-nowrap text-gray-500 font-medium text-sm;
  }

  .footnote {
    @apply text-sm bind-text-gray-400 font-bold tracking-tight;
  }

  .footnote-xs {
    @apply text-xs bind-text-gray-400 font-bold tracking-tight;
  }

  .text-hidden {
    @apply line-clamp-1 whitespace-pre-line text-ellipsis min-w-[3rem] break-words;
  }

  .clickable-primary {
    @apply cursor-pointer text-primary-500 hover:underline underline-offset-2;
  }

  .overflow-overlay {
    overflow: auto !important;
  }
  /* width */
  .overflow-overlay::-webkit-scrollbar {
    background: transparent;
    width: 8px;
    height: 8px;
  }

  /* Track */
  .overflow-overlay::-webkit-scrollbar-track {
    background: transparent;
    width: 0;
  }

  .overflow-overlay::-webkit-scrollbar-track-piece {
    background: transparent;
    width: 0;
  }

  /* Handle */
  .overflow-overlay::-webkit-scrollbar-thumb {
    @apply bg-primary-300 dark:bg-primary-700;
  }

  /* Handle on hover */
  .overflow-overlay::-webkit-scrollbar-thumb:hover {
    @apply bg-primary-400 dark:bg-primary-600;
  }

  .badge {
    @apply select-none border border-gray-200 dark:border-gray-700 bind-text-gray-500 text-xs px-1.5 pt-[0.3rem] pb-1 rounded-full ml-1 h-fit;
  }

  .badge-primary {
    @apply select-none bg-primary-500 bg-opacity-10 text-primary-600 text-xs font-bold px-[0.4rem] pt-[0.3rem] pb-1 rounded-full ml-1 h-fit;
  }

  .badge-gray {
    @apply select-none bg-gray-500 bg-opacity-10 text-gray-600 text-xs font-bold px-[0.4rem] pt-[0.3rem] pb-1 rounded-full ml-1 h-fit;
  }

  .badge-error {
    @apply select-none bg-error-500 bg-opacity-10 text-error-600 text-xs font-bold px-[0.4rem] pt-[0.3rem] pb-1 rounded-full ml-1 h-fit;
  }

  .badge-fuchsia {
    @apply select-none bg-fuchsia-500 bg-opacity-10 text-fuchsia-600 text-xs font-bold px-[0.4rem] pt-[0.3rem] pb-1 rounded-full ml-1 h-fit;
  }

  .badge-success {
    @apply select-none bg-success-500 bg-opacity-10 text-success-500 text-xs font-bold px-[0.4rem] pt-[0.3rem] pb-1 rounded-full ml-1 h-fit;
  }

  .badge-warning {
    @apply select-none bg-warning-500 bg-opacity-10 text-warning-500 text-xs font-bold px-[0.4rem] pt-[0.3rem] pb-1 rounded-full ml-1 h-fit;
  }

  .badge-error {
    @apply select-none bg-error-500 bg-opacity-10 text-error-500 text-xs font-bold px-[0.4rem] pt-[0.3rem] pb-1 rounded-full ml-1 h-fit;
  }

  .msg {
    @apply mt-1.5 text-xs font-medium;
  }

  .sticker-like {
    @apply relative w-full flex hover:shadow-lg active:shadow-md rounded-md hover:ring-4 hover:ring-white hover:ring-opacity-80 dark:hover:ring-gray-800 dark:hover:ring-opacity-80 dark:hover:shadow-black select-none text-gray-400 dark:text-gray-600 transition-all ease-in-out duration-75;
  }

  .controls-base {
    @apply border-0 outline-none bg-transparent placeholder-primary pl-0 indent-4 shadow rounded-primary ring-1 py-3;
  }

  .controls-set-1 {
    @apply ring-gray-200 dark:ring-gray-600 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-500 hover:ring-primary-400 dark:hover:ring-primary-500;
  }

  .controls-base-border-0 {
    @apply border-0 outline-none bg-transparent placeholder-primary p-primary shadow rounded-primary;
  }

  .readOnly-controls {
    @apply bind-text-gray-500 focus:ring-1 focus:ring-gray-100 bg-gray-50 dark:bg-gray-900 dark:bg-opacity-50 ring-1 ring-gray-100 dark:ring-slate-800 cursor-default pointer-events-none;
  }

  .disabled-controls {
    @apply cursor-not-allowed bg-gray-200 bg-opacity-80 dark:bg-gray-750 dark:border-gray-800 ring-gray-300 dark:ring-gray-700 select-none hover:bg-opacity-100 focus:ring-gray-300 dark:focus:ring-offset-gray-700 dark:focus:ring-gray-700 dark:focus-within:ring-offset-gray-700;
    color: #aeb6b6;
  }

  .disabled-circle-controls {
    @apply rounded-full flex items-center border border-transparent border-gray-300 dark:border-gray-800  focus:border-gray-100 dark:focus:border-gray-700 focus-within:border-gray-100 bg-gray-200 bg-opacity-80 dark:bg-gray-750 dark:focus-within:border-gray-700 text-gray-400 hover:text-gray-600 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-300 dark:ring-gray-700 cursor-pointer select-none hover:bg-gray-200 dark:focus:ring-offset-gray-700 dark:focus:ring-gray-700 dark:focus-within:ring-offset-gray-700;
  }

  .date {
    @apply text-xs font-medium min-w-max text-gray-500 dark:text-gray-400;
  }

  .transition-75 {
    /*@apply ease-in-out duration-75;*/
  }

  .transition-100 {
    /*@apply ease-in-out duration-100;*/
  }

  .transition-125 {
    /*@apply ease-in-out;*/
    /*transition-duration: 125ms;*/
  }

  .transition-200 {
    /*@apply ease-in-out duration-200;*/
  }

  .dot {
    @apply h-2 w-2 flex-shrink-0 flex-grow-0 rounded-full;
  }

  .dot-sm {
    @apply h-1 w-1 max-h-1 max-w-[0.25rem] flex-shrink-0 flex-grow-0 rounded-full;
  }

  .dot-xs {
    @apply h-0.5 w-0.5 flex-shrink-0 flex-grow-0 rounded-full;
  }

  .detail {
    @apply text-gray-700 dark:text-gray-300;
  }

  .circle-icon {
    @apply rounded-full flex items-center border border-transparent focus:border-gray-100 dark:focus:border-gray-700 focus-within:border-gray-100 dark:focus-within:border-gray-700 text-gray-400 hover:text-gray-600 cursor-pointer focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-50 dark:focus:ring-offset-gray-800 active:ring-2 active:ring-primary-500 active:ring-offset-2 active:ring-offset-gray-50 dark:active:ring-offset-gray-800;
  }

  .circle-focusable {
    @apply rounded-full p-1 flex items-center text-gray-400 hover:text-gray-600 cursor-pointer focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-800 active:ring-2 active:ring-primary-500 active:ring-offset-2 active:ring-offset-gray-100 dark:active:ring-offset-gray-800 disabled:cursor-default disabled:hover:text-gray-400;
  }

  .circle-focusable-p-0 {
    @apply rounded-full flex items-center text-gray-400 hover:text-gray-600 cursor-pointer focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-800 active:ring-2 active:ring-primary-500 active:ring-offset-2 active:ring-offset-gray-100 dark:active:ring-offset-gray-800 disabled:cursor-default disabled:hover:text-gray-400;
  }

  .box {
    @apply relative rounded-2xl px-4 py-6 sm:p-6 shadow dark:shadow-gray-900 border border-gray-100 dark:border-gray-700 bg-white dark:bg-gray-750;
  }

  .box-p-auto {
    @apply relative rounded-2xl border border-gray-100 dark:border-gray-700 dark:bg-gray-750;
  }

  .numbering {
    @apply flex items-center justify-center w-6 p-1 uppercase dark:font-semibold bg-gray-100 dark:bg-gray-750 text-gray-500;
  }

  .figure-box {
    @apply rounded-lg px-4 py-6 xs:p-6 shadow hover:shadow-lg focus-within:shadow-md dark:focus-within:shadow-lg dark:bg-gray-750 text-gray-600 dark:text-gray-300 dark:hover:text-gray-200 focus-within:font-medium focus-within:text-black dark:focus-within:text-white;
  }

  /* 흑, 백 모드 커버 border-gray-200 */
  .bind-border-gray-200 {
    @apply border-gray-200 dark:border-gray-700;
  }

  .bind-text-gray-200 {
    @apply text-gray-200 dark:text-gray-700;
  }

  .bind-text-gray-300 {
    @apply text-gray-300 dark:text-gray-600;
  }

  .bind-text-gray-400 {
    @apply text-gray-400 dark:text-gray-500;
  }

  .bind-text-gray-500 {
    @apply text-gray-500 dark:text-gray-400;
  }

  .bind-text-gray-600 {
    @apply text-gray-600 dark:text-gray-200;
  }

  .bind-text-gray-700 {
    @apply text-gray-700 dark:text-gray-400;
  }

  .track {
    flex: 1 0 calc(22% + 2px);
    scroll-snap-align: start;
  }

  .rounded-primary {
    @apply rounded-lg;
  }

  .p-full {
    @apply p-5;
  }

  .p-primary {
    @apply px-2 py-2;
  }

  .p-slim {
    @apply px-4 py-1.5;
  }

  .pointable-text {
    @apply hover:underline hover:underline-offset-4 decoration-2 cursor-pointer;
  }


  .msg-400-div {
    @apply bg-red-50 ring-1 ring-red-500 dark:bg-red-500 dark:bg-opacity-5 p-1.5;
  }

  .msg-div {
    @apply rounded-md delay-75 p-0;
  }

  .placeholder-primary {
    @apply placeholder:text-gray-400 dark:placeholder:text-gray-500 placeholder:font-medium placeholder:text-sm placeholder:select-none;
  }

  .placeholder-primary-like {
    @apply font-medium text-gray-400 dark:text-gray-500;
  }

  .tag-indigo {
    @apply text-xs bg-primary-50 text-primary-700 px-1 py-0.5 font-semibold tracking-wide;
  }

  .responsive-label {
    @apply text-xs sm:text-base font-semibold sm:font-normal;
  }

  .nav-after {
    @apply bg-gray-50 dark:bg-[#212c3a] shadow-sm shadow-gray-200 dark:shadow dark:shadow-[#111827bd] border-b border-b-gray-50 dark:border-b-gray-700 border-opacity-50;
    /* @apply shadow-sm shadow-gray-200 dark:shadow-gray-700 backdrop-blur-sm; */
  }

  .nav-before {
    @apply xl:px-40 py-6;
  }

  .card-after {
    @apply p-4 sm:p-6 sm:h-16 bg-gray-50 dark:bg-[#212c3a] shadow-sm shadow-gray-200 dark:shadow dark:shadow-[#111827bd] border-b border-b-gray-50 dark:border-b-gray-700 border-opacity-50;
    /* @apply shadow-sm shadow-gray-200 dark:shadow-gray-700 backdrop-blur-sm; */
  }

  .card-before {
    @apply p-4 md:px-10 md:py-10;
  }

  .blank {
    @apply font-medium bg-gray-50 dark:bg-gray-750 rounded-xl flex items-center justify-center py-8 bind-text-gray-300 select-none;
  }
}

@layer base {
  html {
    @apply min-h-screen max-h-full h-full;
  }
  body {
    @apply min-h-screen max-h-full h-full dark:text-white bg;
    overflow: auto;
    font-family: Pretendard, -apple-system, BlinkMacSystemFont, "Noto Sans KR", Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
  }

  /* clears the 'X' from Internet Explorer */
  input[type="search"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /*
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  } */

  /* clears the 'X' from Chrome */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  input {
    /*@apply py-2;*/
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }

  a {
    @apply font-normal focus:outline-none focus-within:outline-none;
  }

  button {
    @apply focus:outline-none focus-within:outline-none;
  }

  h1 {
    @apply text-3xl font-bold bind-text-gray-600 max-w-max break-words;
  }

  h2 {
    @apply text-2xl font-bold bind-text-gray-600 max-w-max break-words;
  }

  h3 {
    @apply text-xl font-semibold bind-text-gray-600 max-w-max break-words;
  }

  h4 {
    @apply text-lg font-semibold max-w-max;
  }

  h5 {
    @apply text-base font-semibold max-w-max;
  }

  h6 {
    @apply text-sm font-bold bind-text-gray-500 max-w-max my-1;
  }

  /* width */
  /*  윈도우에서 스크롤 바가 예쁘게 안나와서 설정한 부분 */
  /*::-webkit-scrollbar {
    background: transparent;
    width: 10px;
    height: 10px;
  }*/

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    width: 0;
  }

  ::-webkit-scrollbar-track-piece {
    background: transparent;
    width: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-gray-300 dark:bg-gray-750;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-400 dark:bg-gray-600;
  }

  input[type='text']:focus,
  input[type='email']:focus,
  input[type='url']:focus,
  input[type='password']:focus,
  input[type='number']:focus,
  input[type='date']:focus,
  input[type='datetime-local']:focus,
  input[type='month']:focus,
  input[type='search']:focus,
  input[type='tel']:focus,
  input[type='time']:focus,
  input[type='week']:focus,
  input[multiple]:focus,
  textarea:focus,
  select:focus {
    @apply ring-0;
  }

  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    background-color: transparent;
  }

  input[type='text'],
  input[type='email'],
  input[type='url'],
  input[type='password'],
  input[type='number'],
  input[type='date'],
  input[type='datetime-local'],
  input[type='month'],
  input[type='search'],
  input[type='tel'],
  input[type='time'],
  input[type='week'],
  input[multiple],
  textarea,
  select {
    @apply py-4;
  }
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  content: "";
  /* color: #363636; */
  font-size: 0.2rem;
  margin: auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
